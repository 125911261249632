require("@rails/ujs").start()
require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")
require("bootstrap")

require("trix")
require("@rails/actiontext")

import set_zero from './base'
window.set_zero = set_zero